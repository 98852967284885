import { html } from 'lit';
import RouterService from '@seft/router-service';
import type { Route } from '@seft/router-service/types';

export enum Pages {
  Home = 'Home',
}
/**
 * All possible routes in the application, navItem determines if it's directly accessible as a header link.
 */
export const registry: Route<Pages>[] = [
  {
    name: Pages.Home,
    pattern: /^\/$/,
    navItem: true,
    importer: () => import('./home'),
    renderer: () => html`<pages-home></pages-home>`,
  },
];
export function initialize() {
  registry.forEach((route) => RouterService.add(route));
}
